@import url(https://fonts.googleapis.com/css2?family=Akaya+Kanadaka&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Satisfy&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  background-color: #faeae8;
  min-height: 100vh;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h1,h2,h4 {
  color: #1c1918;
}

.main {
  min-height: 100vh;
}

.panel {
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
	border-radius: 5px;
	background: white;
	padding: 2%;
	width: 90%;
	margin: auto;
  margin-top: auto;
}

.image-lg {
  width: 50vh;
  height: 50vh;
}

.add-space {
  margin: 4px;
}

.rating-container {
  display: flex;
}

.rating-container > div {
  padding: 1px;
}

.left-align-cols > div {
  text-align: left;
}

/******************************NAV BAR *********************************/
.navbar-custom {
  background-color:#796460;
}

.navbar-light .navbar-nav .nav-link {
    color: #fff !important;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: #fff76a !important;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28 255, 248, 106, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.navbar-light .navbar-toggler {
    color: rgba(255, 248, 106,.5) !important;
    border-color: rgba(255, 248, 106,.1) !important;
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
}


.error {
  color: #b34436;
}

.page-img {
  width: 100%;
  height: 60vh;
}

iframe {
  width: 100%;
  height: 60vh;
}

section {
  padding: 10%;
}

section:nth-child(odd) {
    background-color: #fff;
}
section:nth-child(even) {
    background-color: #f0e0de;
}

.center-nav {
  margin: auto;
}

.center-nav>a {
  font-family: 'Akaya Kanadaka', cursive;
  font-size: 120%;
}

.paw-img {
  width: 20px;
}


/**************************HERO IMAGE SECTION******************************/

header {
  background-image: linear-gradient(rgba(255, 255, 255, 0.9),rgba(255, 255, 255, 0.9)),url("https://catcafe.chrisbriant.uk/paws.png");
  background-repeat: repeat;
  min-height :88vh;
  overflow: hidden;
  background-color: #f0e0de;
  background-position: center;
  background-attachment: fixed;
}

.logo-image {
  width: 50%;
}



/**********************SECTIONS*********************************************/


/**********************vs image********************************************/
.vsimg-small {
  width: 6rem;
  height: 4rem;
}

/**********************footer********************************************/
footer {
  min-height:10vh;
  background-color: #ebd7a0;
}

/***********************modals******************************************/

.modal-80w {
  min-width:80%;
}


.map-container{
  height: 80vh;
  width: 100%;
}

.center-content {
  margin:auto;
}

/**********************cards************************************************/

.card-header{
  background-color: #796460 !important;
  color: #fff;
}

.card {
  width: 50% !important;
  margin: auto;
}


/**********************calendar************************************************/

.active-day {
  background-color: #f7f3f2;
  border: 1px solid #b8b8b8;
  min-height: 80px;
}

.inactive-day {
  background-color: #e6e6e6;
  border: 1px solid #b8b8b8;
  min-height: 80px;
}

.passed-day {
  background: repeating-linear-gradient(
    45deg,
      #e6e6e6,
      #e6e6e6 10px,
      #b8b8b8 10px,
      #b8b8b8 20px
  );
  border: 1px solid #b8b8b8;
  min-height: 80px;
}

.day-number {
  display: block;
  font-size: 120%;
}

.day-availability {
  display: block;
}

.passed-day {

}

/************************Buttons ****************************/
/*standard button*/
.std-btn {
  font: inherit;
  margin: 2px;
  border: 1px solid #8a7d5b;
  background: #ebd7a0;
  padding: 0.5rem 1rem;
  color: #5e5b54;
  font-size: 80%;
  border-radius: 5px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.26);
  cursor: pointer;
  text-decoration: none;
}


.big-btn {
  font: inherit;
  margin: 2px;
  border: 5px solid #8a7d5b;
  background: #ebd7a0;
  padding: 0.5rem 1rem;
  color: #5e5b54;
  font-size: 120%;
  border-radius: 5px;
  box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.26);
  cursor: pointer;
  text-decoration: none;
}

.big-btn:hover,
.big-btn:active {
  background: #f2dda2;
  border-color: #8a7d5b;
  box-shadow: 5px 5px 18px rgba(77, 51, 51, 0.26);
}


.cal-skip-btn {
  font: inherit;
  border: 1px solid #8a7d5b;
  background: #ebd7a0;
  padding: 0.5rem;
  color: #5e5b54;
  font-size: 80%;
  border-radius: 5px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.26);
  cursor: pointer;
  text-decoration: none;
}


.book-btn {
  font: inherit;
  margin: 2px;
  border: 1px solid #8a7d5b;
  background: #ebd7a0;
  padding: 0.5rem;
  color: #5e5b54;
  font-size: 80%;
  border-radius: 5px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.26);
  cursor: pointer;
  text-decoration: none;
}

.std-btn:hover,
.std-btn:active,
.cal-skip-btn:hover,
.cal-skip-btn:active,
.book-btn:hover,
.book-btn:active {
  background: #f2dda2;
  border-color: #8a7d5b;
  box-shadow: 1px 1px 8px rgba(77, 51, 51, 0.26);
}

.cal-btn-img {
    height:40px;
    width: 40px;
}

.btn-container {
  width:100px;
}

/**********************Day in Calendar************************************************/
.slot-available {
  margin: 2px;
  background-color: #ebd7a0;
  border: 1px solid #b8b8b8;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.26);
  min-height: 40px;
}

.slot-available:hover,
.slot-available:active {
  margin: 2px;
  background: #f2dda2;
  border-color: #8a7d5b;
  box-shadow: 1px 1px 8px rgba(77, 51, 51, 0.26);
  cursor: pointer;
}

.slot-booked {
  margin: 2px;
  background-color: #e6e6e6;
  border: 1px solid #b8b8b8;
  min-height: 40px;
}

.slot-unavailable {
  margin: 2px;
  background: repeating-linear-gradient(
    45deg,
      #e6e6e6,
      #e6e6e6 10px,
      #b8b8b8 10px,
      #b8b8b8 20px
  );
  border: 1px solid #b8b8b8;
  min-height: 40px;
}

.booking-time {
  display: block;
  font-size: 60%;
}

.booking-text {
  display: block;
  font-size: 60%;
}


/**********************Tables******************************************************/

.floorplan {
  width: 100%;
  height: 100%;
}

.svg-table:hover {
  cursor: pointer;
  fill: #f2dda2;
}

.svg-booked-table {
  fill: #e6e6e6;
}

/********************Modals******************************************************/

/*
.modal-80w {
  text-align: center;
  width: 40px;
}

.modal-header {
  text-align: center !important;
}


div.fade.in.modal {
  display:flex !important;
}

.modal-dialog {
  margin: auto;
}

/**********************MENU SECTION ***************************************/
#menu > h1 {
  font-size: 400%;
  font-family: 'Satisfy', cursive;
}

#menu h2 {
  font-size: 300%;
  font-family: 'Satisfy', cursive;
}

#menu h3 {
  font-family: 'Bebas Neue', cursive;
}


#menu .menuItem {
  text-align: left;
}

#menu .menuPrice {
  text-align: right;
}

/**********************Media Queries************************************************/


@media only screen and (max-width: 767px) {
  .card {
    width: 100% !important;
    margin: auto;
  }
}


@media only screen and (max-width: 880px) {
  .book-btn {
    font: inherit;
    margin: 2px;
    border: 1px solid #8a7d5b;
    background: #ebd7a0;
    padding: 0.25rem;
    color: #5e5b54;
    font-size: 80%;
    border-radius: 5px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.26);
    cursor: pointer;
    text-decoration: none;
  }


  .active-day {
    min-height: 60px;
  }

  .inactive-day {
    min-height: 60px;
  }

  .passed-day {
    min-height: 60px;
  }
}


@media only screen and (max-width: 530px) {
  .book-btn {
    font: inherit;
    margin: 2px;
    border: 1px solid #8a7d5b;
    background: #ebd7a0;
    padding: 0.12rem;
    color: #5e5b54;
    font-size: 70%;
    border-radius: 5px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.26);
    cursor: pointer;
    text-decoration: none;
  }

  .day-number {
    display: block;
    font-size: 100%;
  }

  .active-day {
    min-height: 30px;
  }

  .inactive-day {
    min-height: 30px;
  }

  .passed-day {
    min-height: 30px;
  }
}


@media only screen and (max-width: 460px) {
  .book-btn {
    font: inherit;
    margin: 2px;
    border: 1px solid #8a7d5b;
    background: #ebd7a0;
    padding: 0.12rem;
    color: #5e5b54;
    font-size: 50%;
    border-radius: 5px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.26);
    cursor: pointer;
    text-decoration: none;
  }

  .day-number {
    display: block;
    font-size: 80%;
  }

  .active-day {
    min-height: 20px;
  }

  .inactive-day {
    min-height: 20px;
  }

  .passed-day {
    min-height: 20px;
  }
}



@media only screen and (max-width: 410px) {

  .book-btn {
    font: inherit;
    margin: 2px;
    border: 1px solid #8a7d5b;
    background: #ebd7a0;
    padding: 0.12rem;
    color: #5e5b54;
    font-size: 20%;
    border-radius: 5px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.26);
    cursor: pointer;
    text-decoration: none;
  }

  .day-number {
    display: block;
    font-size: 60%;
  }

  .active-day {
    min-height: 10px;
    max-width: 10px;
  }

  .inactive-day {
    min-height: 10px;
    max-width: 10px;
  }

  .passed-day {
    min-height: 10px;
    max-width: 10px;
  }
}

